export enum ABTestKeys {
  CAT2766_app_redirectionABCD = '2024-06-12_cat-2766_app_redirection_v2',
}

export const ABTestsConfig: { [key in ABTestKeys]: string[] } = {
  [ABTestKeys.CAT2766_app_redirectionABCD]: [
    'control',
    'variation_b',
    'variation_c',
    'variation_d',
  ],
};
