export enum TrackingEvent {
  LOANSELECTOR_AMOUNT_ONINTERACT = 'loanselector_amount_oninteract',
  LOANSELECTOR_TERM_ONINTERACT = 'loanselector_term_oninteract',
  LOANSELECTOR_PURPOSE_ONINTERACT = 'loanselector_purpose_oninteract ',
  LOANSELECTOR_INITIALPAYMENT_ONINTERACT = 'loanselector_initialpayment_oninteract',
  LOANSELECTOR_VEHICLEPRICE_ONINTERACT = 'loanselector_vehicleprice_oninteract',
  LOANSELECTOR_FORM_ONSUBMIT = 'loanselector_form_onsubmit',
  PHONEBOX_BUTTON_ONINTERACT = 'phonebox_button_oninteract',
  LOGIN_LINK_ONINTERACT = 'login_link_oninteract',
  LOGIN_LINK_ONINTERACT_MOBILE = 'login_link_oninteract_mobile',
  ERRORPAGE_HOMEBUTTON_ONINTERACT = 'errorpage_homebutton_oninteract',
  ADVANTAGES_BUTTON_ONINTERACT = 'advantages_button_oninteract',
  OPTIMIZELY_EXPERIMENT_VIEWED = 'Experiment Viewed',
  STEPEXPLANATION_BUTTON_ONINTERACT = 'stepexplanation_button_oninteract',
  FAQ_BUTTON_ONINTERACT = 'faq_button_oninteract',
  MOBILE_HEADER_MENULINK_ONINTERACT = 'mobile_header_menulink_oninteract',
  IMAGE_BUTTON_ONINTERACT = 'image_button_oninteract',
  CONTACT_PHONE_BUTTON_ONINTERACT = 'contact_phone_button_oninteract',
  CONTACT_EMAIL_BUTTON_ONINTERACT = 'contact_email_button_oninteract',
  LOAN_VOLUME = 'loanVolume',
  UNSUBSCRIBE_DATA_ONCHANGE = 'unsubscribe_data_onchange',
  REDIRECT_TO_APP = 'redirect_to_app',
}
