/**
 * This config helper adds features and config information to a page url and
 * makes them available via get helper
 */
import { NextParsedUrlQuery } from 'next/dist/server/request-meta';

export interface IPageConfig {
  advertisementId: string;
  configuration?: string;
  formConfig: string;
  isDDF: boolean;
}

const environment = process.env.RELEASE_STAGE;
const partnerpage = 'emailpartner';
export const smavaapp = 'smava_app';

const defaultAdvertisementIds = {
  development: 'j1ORSqOAV3gp',
  shared: 'j1ORSqOAV3gp',
  stage: 'Zb6rU9Gld5vZ',
  live: 'rDLEsxEljzqr',
};

// Direct funnel for page /lp/kreditanfrage
const ddfAdvertisementIds = {
  development: 'j6d8Sx3YrGqp',
  shared: 'j6d8Sx3YrGqp',
  stage: 'BDb1Fa9Vmplx',
  live: 'WN15SXwnVxNr',
};

const infoEmailAdvertisementIds = {
  development: 'J7WPs7ZDO5oJ',
  shared: 'J7WPs7ZDO5oJ',
  stage: 'BporCPbywWoY',
  live: 'WxGpCl6nK6jr',
};

// watch out: the name is without the "Id" suffic
// keys are copy of default advertisement ids
const infoEmailConfiguration = {
  development: 'J7WPs7ZDO5oJ',
  shared: 'J7WPs7ZDO5oJ',
  stage: 'BporCPbywWoY',
  live: 'WxGpCl6nK6jr',
};

// DDF is the new default we leave this option in for now if there is change in plan
// @TODO: Review past 06/2022 if this can be removed
const isDDF = (_url: string) => true;

const isEmailInfoPage = (_url: string) => _url.indexOf(partnerpage) > -1;

const getInfoEmailAdvertisementId = (
  environment: keyof typeof infoEmailAdvertisementIds,
  query: NextParsedUrlQuery = {},
) => {
  if (query.advertisementId) {
    return String(query.advertisementId);
  }

  if (query.refId) {
    return String(query.refId);
  }

  return infoEmailAdvertisementIds[environment];
};

const getInfoEmailConfiguration = (
  environment: keyof typeof infoEmailConfiguration,
  query: NextParsedUrlQuery = {},
) => {
  if (query.configuration) {
    return String(query.configuration);
  }

  return infoEmailConfiguration[environment];
};

function getConfiguration(url: string, query: NextParsedUrlQuery = {}) {
  if (isEmailInfoPage(url)) {
    return getInfoEmailConfiguration(environment, query);
  }

  return '';
}

function getAdvertisementId(url: string, query: NextParsedUrlQuery = {}) {
  if (isEmailInfoPage(url)) {
    return getInfoEmailAdvertisementId(environment, query);
  }

  if (isDDF(url)) {
    return ddfAdvertisementIds[environment];
  }

  return defaultAdvertisementIds[environment];
}

const DEFAULT_FORM_CONFIG = 'smava';
const DDF_FORM_CONFIG = 'ddf_smava';
const DDF_FORM_MARKETING_CONFIG = 'ddf_smava_marketing';

function getFormConfig(url: string) {
  if (url.includes('-dac')) {
    return DDF_FORM_MARKETING_CONFIG;
  }

  if (isDDF(url)) return DDF_FORM_CONFIG;

  return DEFAULT_FORM_CONFIG;
}

/*
 * Gathers all the config data and return one comprehensive page config
 */
export function getPageConfig(
  url: string,
  query: NextParsedUrlQuery,
): IPageConfig {
  const configurationId = getConfiguration(url, query);

  return {
    advertisementId: getAdvertisementId(url, query),
    ...(configurationId && { configurationId }),
    formConfig: getFormConfig(url),
    isDDF: isDDF(url),
  };
}
